import React from "react";
import { Link } from "gatsby";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Kontakt</h5>
              </div>
              <ul>
                <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6>Adres korespondencyjny</h6>
                    <p>
                      ul. Szuwarów 7/5
                      <br />
                      87-100 Toruń
                    </p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>E-mail</h6>
                    <p>
                      <a href="mailto:rafal@tymoszuk.com">rafal@tymoszuk.com</a>
                    </p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-call"></span>
                  <div className="cont">
                    <h6>Telefon</h6>
                    <p>+48 506 445 966</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="item md-mb50">
              <div className="title">
                <h5>O mnie</h5>
              </div>
              <p>
                Od ponad 20 lat zajmuje się{" "}
                <strong>tworzeniem stron internetowych</strong>. Dzięki
                szerokiemu doświadczeniu zdobytemu przez lata pracy, doskonale
                rozumiem zarówno techniczne, jak i kreatywne aspekty tworzenia
                nowoczesnych witryn.
                <br />
                Od 2011 r. pracuję w firmie Possible sp. z o.o. jako front-end
                developer, gdzie odpowiadam za projektowanie i implementację
                interfejsów użytkownika. Moje główne umiejętności obejmują
                tworzenie responsywnych stron, optymalizację wydajności oraz
                integrację zaawansowanych technologii frontendowych, takich jak
                React, JavaScript, HTML, i CSS. <br />
                Oferuję również <strong>tworzenie unikalnych grafik</strong>,
                logo, materiałów reklamowych oraz elementów identyfikacji
                wizualnej, które wyróżnią Twoją markę na tle konkurencji.
                <br />
              </p>
            </div>
            <div className="item">
              <div className="copy-right">
                <p>
                  © 2024 Made with passion by{" "}
                  <Link to="#" target="_blank">
                    Rafał Tymoszuk
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

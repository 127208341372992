import React from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { handleMobileDropdown } from "common/navbar";

const Navbar = ({ lr, nr, theme }) => {
  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change ${
        theme === "themeL" ? "light" : ""
      }`}
    >
      <div className="container">
        <Link to="/" className="logo">
          <svg
            version="1.1"
            id="logo"
            x="0px"
            y="0px"
            width="160px"
            viewBox="0 0 267.078 47.184"
            enable-background="new 0 0 267.078 47.184"
          >
            <g>
              <path
                d="M2.578,6.969h4.594l0.938,3.984h6.188v4.922H8.789v11.32c0,2.297,0.992,3.445,2.977,3.445h2.531v4.922
		H8.766c-4.125,0-6.188-2.211-6.188-6.633V6.969z"
              />
              <path
                d="M15.586,10.953h6.586l6.211,17.719l6.07-17.719h6.469L30.703,36.289c-1.813,4.469-4.711,7.648-8.695,9.539
		l-3-4.242c2.75-1.203,4.75-3.164,6-5.883L15.586,10.953z"
              />
              <path
                d="M43.852,35.563V10.953h4.805l0.727,3.563c2.203-2.375,4.852-3.563,7.945-3.563s5.211,1.125,6.352,3.375
		c2.516-2.25,5.148-3.375,7.898-3.375c5.656,0,8.484,3.117,8.484,9.352v15.258h-6.211V20.07c0-2.734-1.234-4.102-3.703-4.102
		c-1.844,0-3.618,0.828-5.32,2.484v17.109h-6.211V20.141c0-2.781-1.141-4.172-3.422-4.172c-1.813,0-3.524,0.828-5.133,2.484v17.109
		H43.852z"
              />
              <path
                d="M84.75,23.258c0-8.25,4.063-12.375,12.188-12.375c8.125,0,12.188,4.125,12.188,12.375
		c0,8.234-4.063,12.352-12.188,12.352C88.843,35.609,84.781,31.492,84.75,23.258z M96.938,30.875c3.984,0,5.977-2.57,5.977-7.711
		c0-5.016-1.992-7.523-5.977-7.523s-5.977,2.508-5.977,7.523C90.961,28.305,92.953,30.875,96.938,30.875z"
              />
              <path
                d="M113.813,34.391v-5.156c2.734,1.094,5.89,1.641,9.468,1.641c2.672,0,4.008-0.859,4.008-2.578
		c0-1.641-0.898-2.461-2.695-2.461h-4.453c-5,0-7.5-2.461-7.5-7.383c0-5.156,3.648-7.734,10.945-7.734
		c3.094,0,6.008,0.469,8.742,1.406v5.156c-2.734-1.094-5.703-1.641-8.906-1.641c-3.359,0-5.039,0.859-5.039,2.578
		c0,1.641,0.977,2.461,2.93,2.461h3.984c5.469,0,8.203,2.461,8.203,7.383c0,5.156-3.484,7.734-10.453,7.734
		C119.625,35.797,116.546,35.328,113.813,34.391z"
              />
              <path d="M137.718,10.953h18.985v4.922l-12.586,15h12.117v4.688H137.25v-4.688l12.774-15h-12.305V10.953z" />
              <path
                d="M183.188,10.953v24.609h-4.852l-0.75-3.141c-2.828,2.094-5.828,3.141-9,3.141
		c-5.031,0-7.547-2.977-7.547-8.93v-15.68h6.211v15.516c0,2.719,1.188,4.078,3.563,4.078c2.016,0,4.07-0.805,6.164-2.414v-17.18
		H183.188z"
              />
              <path
                d="M189.047,35.563V2.047h6.211v10.828c2.547-1.281,5.227-1.922,8.039-1.922c6.281,0,9.422,2.367,9.422,7.102
		c0,3.438-2.188,6.125-6.563,8.063l7.641,9.445h-7.594l-6.422-8.789v-2.883c4.484-0.953,6.727-2.516,6.727-4.688
		c0-2.219-1.484-3.328-4.453-3.328c-2.078,0-4.344,0.656-6.797,1.969v17.719H189.047z"
              />
              <path d="M217.723,33.395v2.168h-2.168v-2.168H217.723z" />
              <path
                d="M229.91,35.211c-1.016,0.234-2.109,0.352-3.281,0.352c-4.375,0-6.563-2.129-6.563-6.387
		c0-3.945,2.188-5.918,6.563-5.918c1.172,0,2.266,0.117,3.281,0.352v1.758c-1.016-0.234-2.148-0.352-3.398-0.352
		c-2.852,0-4.277,1.387-4.277,4.16c0,3.086,1.426,4.629,4.277,4.629c1.25,0,2.383-0.117,3.398-0.352V35.211z"
              />
              <path
                d="M232.078,29.41c0-4.102,1.953-6.152,5.859-6.152s5.859,2.051,5.859,6.152c0,4.094-1.953,6.141-5.859,6.141
		C234.047,35.551,232.094,33.504,232.078,29.41z M237.938,33.805c2.461,0,3.691-1.48,3.691-4.441c0-2.898-1.23-4.348-3.691-4.348
		s-3.691,1.449-3.691,4.348C234.246,32.324,235.477,33.805,237.938,33.805z"
              />
              <path
                d="M246.727,35.563V23.258h1.465l0.363,1.547c1.18-1.031,2.465-1.547,3.855-1.547
		c1.781,0,2.996,0.563,3.645,1.688c1.336-1.125,2.691-1.688,4.066-1.688c2.828,0,4.242,1.559,4.242,4.676v7.629h-2.168v-7.746
		c0-1.836-0.773-2.754-2.32-2.754c-1.156,0-2.238,0.531-3.246,1.594v8.906h-2.168v-7.711c0-1.859-0.766-2.789-2.297-2.789
		c-1.219,0-2.309,0.531-3.27,1.594v8.906H246.727z"
              />
            </g>
          </svg>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileDropdown}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                onClick={handleMobileDropdown}
                to={`/#uslugi`}
                className="nav-link"
              >
                Oferta
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleMobileDropdown}
                to={`/#portfolio`}
                className="nav-link"
              >
                Realizacje
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleMobileDropdown}
                to={`/#referencje`}
                className="nav-link"
              >
                Referencje
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleMobileDropdown}
                to={`/#kontakt`}
                className="nav-link"
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

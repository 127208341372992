import React from "react";
import ClientsData from "data/sections/clients.json";
import Split from "../Split";

const Clients = ({ theme }) => {
  var first = ClientsData.slice(0, ClientsData.length / 2);
  var second = ClientsData.slice(4, ClientsData.length);
  return (
    <section id="referencje" className="clients section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 valign">
            <div className="sec-head text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Zleceniodawcy
              </h6>
              <h3 className="wow mb-20 color-font">Firmy, które mi zaufały!</h3>
            </div>
          </div>
          <div className="col-lg-12">
            <div>
              <div className="row bord">
                {first.map((item) => (
                  <div key={item.id} className="col-md-3 col-6 brands">
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`${
                        item.id == 1
                          ? ".3"
                          : item.id == 2
                          ? ".6"
                          : item.id == 3
                          ? ".8"
                          : item.id == 4
                          ? ".3"
                          : ""
                      }s`}
                    >
                      <div className={`img client_${item.id}`}>
                        <Split>
                          <a
                            href={`http://${item.url}`}
                            rel="nofollow"
                            target="_blank"
                            className="link words chars splitting"
                            data-splitting
                          >
                            {item.url}
                          </a>
                        </Split>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                {second.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      item.id == 5
                        ? "col-md-3 col-6 brands sm-mb30"
                        : item.id == 6
                        ? "col-md-3 col-6 brands sm-mb30"
                        : item.id == 7
                        ? "col-md-3 col-6 brands"
                        : item.id == 8
                        ? "col-md-3 col-6 brands"
                        : ""
                    }`}
                  >
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`${
                        item.id == 1
                          ? ".4"
                          : item.id == 2
                          ? ".7"
                          : item.id == 3
                          ? ".5"
                          : item.id == 4
                          ? ".3"
                          : ""
                      }s`}
                    >
                      <div className={`img client_${item.id}`}>
                        <Split>
                          <a
                            href={`http://${item.url}`}
                            rel="nofollow"
                            target="_blank"
                            className="link words chars splitting"
                            data-splitting
                          >
                            {item.url}
                          </a>
                        </Split>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;

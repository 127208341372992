import React, { useEffect, useRef } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import FreelancreIntro from "components/Freelancre-intro/freelancre-intro";
import Services5 from "components/Services5/services5";
import WorksStyle4 from "components/Works-style4/works-style4";
import Clients from "components/Clients/clients";
import CallToAction from "components/Call-to-action/call-to-action";
import LightTheme from "layouts/Light";
import DarkTheme from "layouts/Dark";

const Homepage = () => {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);

  useEffect(() => {
    const setTheme = () => {
      const currentHour = new Date().getHours();
      const theme = currentHour >= 6 && currentHour < 20 ? "light" : "dark";

      // Sprawdź, czy styl już istnieje
      let themeLink = document.getElementById("theme-style");

      if (!themeLink) {
        themeLink = document.createElement("link");
        themeLink.id = "theme-style";
        themeLink.rel = "stylesheet";
        document.head.appendChild(themeLink);
      }

      // Ustaw ścieżkę do odpowiedniego arkusza stylów
      themeLink.href = theme === "light" ? "/css/light.css" : "/css/dark.css";
      document.documentElement.setAttribute("data-theme", theme);
    };

    setTheme(); // Ustawia temat przy montowaniu komponentu

    // Opcjonalnie: interwał do aktualizacji co godzinę
    const intervalId = setInterval(setTheme, 3600000); // 1 godzina

    return () => clearInterval(intervalId); // Czyści interwał
  }, []);

  useEffect(() => {
    const navbar = navbarRef.current;

    const handleScroll = () => {
      if (navbar) {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      }
    };

    // Dodanie event listenera dla scrolla
    window.addEventListener("scroll", handleScroll);

    // Usuwanie event listenera przy odmontowaniu komponentu
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <LightTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <FreelancreIntro />
      <Services5 />
      <WorksStyle4 />
      <Clients />
      <CallToAction />
      <Footer />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>
        Rafał Tymoszuk - strony WWW & projektowanie graficzne - Toruń
      </title>
    </>
  );
};

export default Homepage;

import React from "react";
import { Script } from "gatsby";

import ScrollToTop from "components/scrollToTop";

import "styles/main.css";

const LightTheme = ({ children, mobileappstyle, useSkin }) => {
  React.useEffect(() => {
    if (mobileappstyle) {
      let mobileAppCssLink = document.createElement('link');
      mobileAppCssLink.rel = "stylesheet";
      mobileAppCssLink.href = "/css/mobile-app-light.css";
      document.head.appendChild(mobileAppCssLink)
    }
    
    if (useSkin) {
      let skinCssLink = document.createElement('link');
      skinCssLink.rel = "stylesheet";
      skinCssLink.href = "/css/arch-skin-light.css";
      document.head.appendChild(skinCssLink)
    }
  }, [mobileappstyle, useSkin]);
  
  return (
    <>
      { children }
      <ScrollToTop />

      <Script src="/js/wowInit.js"></Script>
    </>
  );
};

export default LightTheme;

import React, { useState } from "react";
import { Link } from "gatsby";
import initIsotope from "common/initIsotope";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const WorksStyle4 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      initIsotope();
    }, 1000);
  }, []);

  const graphics = [
    {
      src: "/img/portfolio/freelancer/7.jpg",
      title: "Projekt wizytówki",
      format: "format: 90x50 mm",
    },
    {
      src: "/img/portfolio/freelancer/11.jpg",
      title: "Projekt wizytówki",
      format: "format: 90x50 mm",
    },
    {
      src: "/img/portfolio/freelancer/13.jpg",
      title: "Projekt teczki",
      format: "format: A4",
    },
    {
      src: "/img/portfolio/freelancer/14.jpg",
      title: "Projekt teczki",
      format: "format: A4",
    },
    {
      src: "/img/portfolio/freelancer/16.jpg",
      title: "Projekt teczki",
      format: "format: A4",
    },
    {
      src: "/img/portfolio/freelancer/10.jpg",
      title: "Papier firmowy",
      format: "format: A4",
    },
    {
      src: "/img/portfolio/freelancer/12.jpg",
      title: "Projekt ulotki",
      format: "format: A5",
    },
    {
      src: "/img/portfolio/freelancer/8.jpg",
      title: "Projekt ulotki składanej",
      format: "format: A5",
    },
    {
      src: "/img/portfolio/freelancer/9.jpg",
      title: "Projekt ulotki składanej",
      format: "format: A5",
    },
    {
      src: "/img/portfolio/freelancer/15.jpg",
      title: "Projekt ulotki składanej",
      format: "format: DL",
    },
  ];

  return (
    <section id="portfolio" className="portfolio-frl section-padding pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Portfolio
              </h6>
              <h3 className="wow color-font">
                Wybrane realizacje stron WWW <br /> i projektów graficznych
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="filtering col-12">
            <div className="filter wow fadeIn" data-wow-delay=".5s">
              <span data-filter="*" className="active">
                Wszystkie
              </span>
              <span data-filter=".web">Strony WWW</span>
              <span data-filter=".graphic">Grafika</span>
            </div>
          </div>

          <div className="gallery full-width">
            <div
              className="col-md-6 web items lg-mr wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>ODF - Centrum Ubezpieczeń i Kredytów</h6>
                  <p>www.odf.pl</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`https://www.odf.pl`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/1.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>WordPress</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 items web wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>DAREX - Hurtownia spożywcza</h6>
                  <p>www.darex-zary.pl</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`https://darex-zary.pl`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/2.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>HTML / CSS / JS</span>
                  <span>PHP / MySQL</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 items web wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>Grupa NDU</h6>
                  <p>www.grupandu.pl</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`https://grupandu.pl`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/4.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>HTML / CSS / JS</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 items web wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>Zielony Program Ekologiczny</h6>
                  <p>www.zielonyprogram.eu</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`https://zielonyprogram.eu`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/3.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>WordPress</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 items web wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>Performed - ratownictwo medyczne</h6>
                  <p>www.performed.pl</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`https://performed.pl/`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/6.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>WordPress</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 items web wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="item-img">
                <div className="cont">
                  <h6>LogicPoint - Centrum Edukacji</h6>
                  <p>www.logicpoint.com.pl</p>
                </div>
                <Link
                  target="_blank"
                  rel="nofollow"
                  to={`http://logicpoint.com.pl/`}
                  className="rota"
                >
                  <img src="/img/portfolio/freelancer/5.jpg" alt="image" />
                  <div className="item-img-overlay"></div>
                </Link>
                <div className="tags">
                  <span>WWW</span>
                  <span>WordPress</span>
                </div>
              </div>
            </div>

            {graphics.map((image, index) => (
              <div
                className="col-md-6 items graphic wow fadeInUp"
                data-wow-delay=".4s"
                key={index}
              >
                <div className="item-img">
                  <div className="cont">
                    <h6>{image.title}</h6>
                  </div>

                  <img
                    src={image.src}
                    alt={image.title}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPhotoIndex(index);
                      setIsOpen(true);
                    }}
                  />
                  <div className="item-img-overlay"></div>

                  <div className="tags">
                    <span>{image.format}</span>
                  </div>
                </div>
              </div>
            ))}

            {isOpen && (
              <Lightbox
                mainSrc={graphics[photoIndex].src}
                nextSrc={graphics[(photoIndex + 1) % graphics.length].src}
                prevSrc={
                  graphics[(photoIndex + graphics.length - 1) % graphics.length]
                    .src
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + graphics.length - 1) % graphics.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % graphics.length)
                }
                // Wyświetl tytuł obrazka
                imageCaption={
                  "[" +
                  (photoIndex + 1) +
                  "/" +
                  graphics.length +
                  "] " +
                  graphics[photoIndex].title
                }
                enableZoom={false}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorksStyle4;
